import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VContent,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Login")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}}),(_vm.failedAuth)?_c(VCol,{staticStyle:{"color":"red"},attrs:{"cols":"8"}},[_c(VIcon,{attrs:{"large":"","color":"red darken-2"}},[_vm._v(" mdi-lock-alert-outline ")]),_vm._v(" "+_vm._s(" ")+" Incorrect username or password ! ")],1):_vm._e(),_c(VCol,{attrs:{"cols":"1"}})],1),_c(VRow,[_c(VCol,{attrs:{"cols":"2"}}),_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"outlined":"","prepend-icon":"mdi-account-box-outline","label":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c(VCol,{attrs:{"cols":"1"}})],1),_c(VRow,[_c(VCol,{attrs:{"cols":"2"}}),_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"outlined":"","prepend-icon":"mdi-lock-outline","type":"password","label":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCol,{attrs:{"cols":"1"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mb-2 white--text",attrs:{"width":"150","height":"40","color":"primary"},on:{"click":_vm.Login}},[_vm._v("Login")]),_c(VSpacer)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }